<template>
    <ion-page data-pageid="home">
        <ion-content :scroll-events="false">
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <DxButton
                            icon="mdi mdi-chevron-left"
                            type="default"
                            @click="QuayLai"
                        />
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center">THÔNG BÁO</div>
                    </slot>
                    <slot name="after">
                        <DxButton
                            icon="mdi mdi-chevron-left"
                            type="default"
                            style="opacity: 0"
                        />
                    </slot>
                </div>
            </ion-toolbar>
            <div class="px-2 py-1">
                <div class="row align-center justify-space-between" style="height: 32px">
                    <div class="top-left">
                        <div class="row align-center">
                            <div class="notify-icon mr-1"></div>
                            <div
                                style="opacity: 0.87; position: relative; top: 1.3px"
                                class="shrink font-medium"
                            >
                                {{ GetTextThoiGian(chiTietThongBao.thoiGianThongBao) }}
                            </div>
                        </div>
                    </div>
                    <div class="top-right">
                        <!-- <i
                            class="mdi mdi-delete-outline color-error"
                            @click="showPopup('PopupXacNhanXoa')"
                        ></i> -->
                        <!-- <DxDropDownButton
                    :items="['Xóa']"
                    :drop-down-options="{
                        width: 230,
                        wrapperAttr: {
                            class: 'custom-dropdown-button',
                        },
                    }"
                    icon="mdi mdi-dots-vertical"
                    :showArrowIcon="false"
                    @chiTietThongBao-click="onItemClick"
                    stylingMode="text"
                /> -->
                    </div>
                </div>

                <div class="display-inline"></div>

                <div class="row mt-2">
                    <div
                        class="ellipsis-text grow color-primary font-16 font-bold"
                        style="letter-spacing: 0.4px"
                    >
                        {{ chiTietThongBao.tieuDe }}
                    </div>
                </div>

                <div
                    class="row align-center justify-space-between color-087 mt-2"
                    style="white-space: break-spaces"
                >
                    {{ chiTietThongBao.noiDung }}
                </div>

                <div
                    class="row font-medium font-16 mt-3"
                    v-if="
                        chiTietThongBao.maHanhDong ==
                        $t('MaHanhDongThongBao.DOANH_NGHIEP_KY_LENH')
                    "
                >
                    Thông tin ký lệnh
                </div>

                <div
                    class="frame-thong-tin-ky-lenh"
                    v-if="
                        chiTietThongBao.maHanhDong ==
                        $t('MaHanhDongThongBao.DOANH_NGHIEP_KY_LENH')
                    "
                >
                    <div class="mt-1 color-087">
                        - Doanh nghiệp:
                        <span class="font-medium">
                            {{ ThongTinKyLenh?.TenDoanhNghiep }}
                        </span>
                    </div>
                    <!-- <div class="mt-1 color-087">- Lái xe:</div>
                    <div
                        class="font-medium"
                        v-for="item in ThongTinKyLenh?.DanhSachLaiXe || []"
                        :key="item"
                    >
                        + {{ item }}
                    </div> -->
                    <div class="my-2 color-087">- Danh sách lệnh:</div>
                    <div
                        class="frame-card-lenh mb-3"
                        v-for="item in ThongTinKyLenh?.DanhSachLenh || []"
                        :key="item"
                        @click="XemChiTietLenh(item)"
                    >
                        <div class="mt-1 color-087">
                            + Mã lệnh:
                            <span class="font-medium">
                                {{ item.MaSoLenh ? item.MaSoLenh : "" }}
                            </span>
                        </div>
                        <div class="mt-1 color-087">
                            + Biển kiểm soát:
                            <span class="font-medium">
                                {{ item.BienKiemSoat ? item.BienKiemSoat : "" }}
                            </span>
                        </div>
                        <div class="mt-1 color-087">
                            + Bến đi:
                            <span class="font-medium">
                                {{ item.BenDi ? item.BenDi : "" }}
                            </span>
                        </div>
                        <div class="mt-1 color-087">
                            + Bến đến:
                            <span class="font-medium">
                                {{ item.BenDen ? item.BenDen : "" }}
                            </span>
                        </div>
                        <div class="mt-1 color-087">
                            + Mã tuyến:
                            <span class="font-medium">
                                {{ item.MaTuyen ? item.MaTuyen : "" }}
                            </span>
                        </div>
                        <div class="mt-1 color-087">
                            + Giờ xuất bến:
                            <span class="font-medium">
                                {{ $Helper.Common.formatDateTime(item.ThoiGianXuatBen) }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import PopupVue from "../../components/_Common/Popup";
import ThanhTieuDeVue from "../../components/_Common/ThanhTieuDe";
import ThongBaoVue from "../../components/_Common/ThongBao";
import { IonContent, IonPage } from "@ionic/vue";
import { DxButton } from "devextreme-vue";
import moment from "moment";

export default {
    layout: "application",
    components: {
        ThanhTieuDeVue,
        PopupVue,
        ThongBaoVue,
        IonContent,
        IonPage,
        DxButton,
    },
    props: {},
    data() {
        return {
            model: {},
            dialogThongBao: false,
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            chiTietThongBao: {
                idThongBao: null,
                tieuDe: null,
                noiDung: null,
                maHanhDong: null,
                duLieu: null,
                thoiGianThongBao: null,
            },
        };
    },
    computed: {
        ThongTinKyLenh() {
            return JSON.parse(this.chiTietThongBao.duLieu);
        },
    },
    methods: {
        QuayLai() {
            this.$router.go(-1);
        },
        GetDay(date) {
            let tmpDate = new Date().setDate(new Date().getDate() - 1);
            if (new Date().toLocaleDateString() == new Date(date).toLocaleDateString()) {
                return "Hôm nay, ";
            } else if (
                new Date(tmpDate).toLocaleDateString() ==
                new Date(date).toLocaleDateString()
            ) {
                return "Hôm qua, ";
            }
            return "";
        },
        GetTextThoiGian(date) {
            return `${this.GetDay(date)}${moment(date).format("HH:mm DD/MM/yyyy")}`;
        },
        async LayChiTietThongBao(idThongBao) {
            try {
                let rs = await this.$Events.RequestGet({
                    ServicesBE: "ThongBao",
                    url: this.$t("urlThongBao.ChiTietThongBao"),
                    dataGet: { idThongBao: idThongBao },
                    msgSuccess: "",
                    msgError: "Lấy chi tiết thông báo thất bại!",
                    msgCatch: "Lấy chi tiết thông báo thất bại!",
                });
                if (!rs.status) {
                    return;
                }
                this.chiTietThongBao = rs.data;
            } catch (error) {
                console.log("🚀 ~ LayChiTietThongBao ~ error:", error);
            }
        },
        XemChiTietLenh(itemLenh) {
            this.$router.go(-1);
            setTimeout(() => {
                this.$router.push({
                    path: "/tabs/LenhDienTu",
                    query: {
                        isChiTiet: true,
                        guidLenh: itemLenh.IdLenh,
                        laLenhGiay: false,
                    },
                });
            }, 100);
        },
    },
    created() {},
    mounted() {
        setTimeout(() => {
            let query = this.$route.query;
            if (!query.idThongBao) {
                return this.$Helper.ThongBaoToast(
                    "error",
                    "Lấy chi tiết thông báo thất bại! Đường dẫn không hợp lệ",
                );
            }
            this.LayChiTietThongBao(query.idThongBao);
        }, 100);
    },
};
</script>
<style scoped></style>
<style lang="scss" scoped>
.frame-box {
    border-radius: 8px;
    box-shadow: 0px 3px 8px #ccc;
}
.notify-icon {
    width: 20px;
    height: 20px;
    // background: green;
    border-radius: 100%;
    background-image: url("../../assets/images/logo.png");
    background-size: 100%;
}
.ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.display-inline {
    width: 100%;
    height: 1px;
    background: #dadce0;
}
.frame-card-lenh {
    border-radius: 8px;
    box-shadow: 0px 3px 8px #ccc;
    padding: 8px;
}
</style>
